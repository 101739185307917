@use "@porsche-design-system/components-react/styles" as *;

.heading-text-flebox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  height: 100%;
}

.data-volume-flexbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-top: 2rem;

  @include pds-media-query-min('s') {
    padding-bottom: $pds-spacing-static-x-large;
  }

  .data-volume-component {
    @include pds-media-query-min-max('base', 's') {
      margin-top: $pds-spacing-static-medium;
      margin-bottom: $pds-spacing-static-large;

    }
    @include pds-media-query-min('s') {
      margin-top: $pds-spacing-static-large;
      margin-bottom: $pds-spacing-static-x-large;
    }
  }

  .buttons-flexbox {
    display: flex;

    @include pds-media-query-min-max('base', 's') {
      flex-direction: column;

      .increase-data-volume {
        margin-bottom: $pds-spacing-static-medium;
      }
    }

    @include pds-media-query-min('s') {
      .increase-data-volume {
        margin-right: $pds-spacing-static-medium;
      }
    }
  }
}


.banner-grid {
  display: flex;
  flex-direction: column;
  @include pds-media-query-min-max('base', 'm') {
    display: none;
  }

  @include pds-media-query-min('l') {
    margin-bottom: $pds-spacing-static-medium;
  }

  .registered-banner {
    width: 80%;
    align-self: flex-end;
  }
}

.register-button {
  width: 100%;
  margin-left: 3rem;
}